import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Home() {
    return (
        <Stack sx={{ maxWidth: 500 }}>
            <Typography variant="h4" component="h1">
                Lifeboard
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
                Matt's clearly over-engineered attempt at a solution to managing life and marriage
            </Typography>
        </Stack>
    );
}
