import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import PageViewsChart from '../components/PageViewsChart';

// const data = [
//     {
//         title: 'Users',
//         value: '14k',
//         interval: 'Last 30 days',
//         trend: 'up',
//         data: [2, 4, 3, 5, 7, 6, 8, 10],
//     },
//     {
//         title: 'Conversions',
//         value: '325',
//         interval: 'Last 30 days',
//         trend: 'down',
//         data: [10, 7, 6, 8, 5, 4, 4, 2],
//     },
//     {
//         title: 'Event count',
//         value: '200k',
//         interval: 'Last 30 days',
//         trend: 'neutral',
//         data: [5, 4, 6, 3, 4, 3, 7, 6],
//     },
//     {
//         title: 'Engagements',
//         value: '30k',
//         interval: 'Last 30 days',
//         trend: 'up',
//         data: [2, 4, 3, 4, 5, 4, 7, 8],
//     },
// ];

const RecordPage = () => {
    return (
        <React.Fragment>
            <Grid size={{ xs: 12, md: 8, lg: 9 }}>
                <Stack spacing={2}>
                    <PageViewsChart />
                </Stack>
            </Grid>
        </React.Fragment>
    );
};

export default RecordPage;