
import React, { Component } from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function createData(need, date, rating) {
    return { need, date, rating };
}

const needs = [
    'Intimacy',
    'Accomplishment',
    'Quiet',
    'Fitness'
];


// var needRecords = [
//     createData(needs[1], '7/4/24', 5),
//     createData(needs[2], '7/3/24', 6),
//     createData(needs[3], '7/2/24', 3),
//     createData(needs[0], '7/1/24', 8),
// ];






function valuetext(value) {
    return `${value} foo`;
}



class RecordPage extends Component {
    constructor(props) {
        super(props)

        // TODO - should we set state like this? 
        // const [array,setArray] = useState([]);

        // Set initial state 
        this.state = {
            needRecords: [
                createData(needs[1], '7/4/24', 5),
                createData(needs[2], '7/3/24', 6),
                createData(needs[3], '7/2/24', 3),
                createData(needs[0], '7/1/24', 8),
            ],
            need: '',
            rating: 5,
        }

        // Binding this keyword 
        // this.updateState = this.updateState.bind(this)

    }

    handleNeedChange = (e) => {

        this.setState({ need: e.target.value }, () => {
            console.log(this.state.need, 'need');
        });

    }

    handleRatingChange = (e) => {

        this.setState({ rating: e.target.value }, () => {
            console.log(this.state.rating, 'rating');
        });

    }

    handleSubmit = (e) => {
        // setRating(e.target.value);

        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currentDate = `${day}-${month}-${year}`;

        console.log("-----------------")
        console.log(this.state.need)
        console.log(currentDate)
        console.log(this.state.rating)

        let newRow = { need: this.state.need, date: currentDate, rating: this.state.rating };
        this.setState({ needRecords: [...this.state.needRecords, newRow] }, () => {
            console.log(this.state.needRecords[this.state.needRecords.length - 1], 'needRecords (last item)');
        })

    };

    render = (e) => {
        return (
            <React.Fragment>


                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Stack spacing={2} direction="row">
                        <InputLabel id="need-select-label">Need</InputLabel>
                        <Select
                            labelId="need-select-label"
                            id="need-select"
                            value={this.state.need}
                            label="Need"
                            style={{
                                minWidth: 200,
                                display: 'flex',
                            }}
                            onChange={this.handleNeedChange}
                        >
                            {needs.map((need) => (
                                <MenuItem value={need}>{need}</MenuItem>
                            ))}
                        </Select>
                        {/* <Slider aria-label="Rating" defaultValue={5} onChange={handleRatingChange} /> */}

                        <Slider
                            aria-label="Rating"
                            defaultValue={5}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            shiftStep={30}
                            step={1}
                            min={1}
                            max={10}
                            onChange={this.handleRatingChange}
                        />

                        <Button onClick={this.handleSubmit} variant="contained">Record Value</Button>
                    </Stack>
                </FormControl>

                <Grid container spacing={2} columns={12}>
                    {/* {data.map((card, index) => (
                        <Grid key={index} size={{ xs: 6, sm: 3, md: 2, lg: 2.25 }}>
                            <StatCard {...card} />
                        </Grid>
                    ))}
                    <Grid size={{ xs: 12, md: 4, lg: 3 }}>
                        <HighlightedCard />
                    </Grid> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="needs records">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.needRecords.map((row) => (
                                    <TableRow
                                        key={row.need}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.need}
                                        </TableCell>
                                        <TableCell align="right">{row.date}</TableCell>
                                        <TableCell align="right">{row.rating}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </React.Fragment>
        );
    }
}

export default RecordPage;